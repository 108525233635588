/* eslint-disable prettier/prettier */
"use strict";

exports.emailRules = [
    function(v) {
        return !!v || "El email es obligatorio.";
    },
    /*,
            function(v) {
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(v) || "El Email no es válido.";
            }*/
];

exports.passwordRules = [
    function(v) {
        return !!v || "La contraseña es obligatoria.";
    },
    /*,
            function(v) {
                return v.length > 6 || "La contraseña debe ser mayor a 8 caracteres.";
            }*/
];

exports.formatDate = function(date) {
    if (!date) return null;
    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
};

exports.parseDate = function(date) {
    if (!date) return null;
    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
};